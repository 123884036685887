import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import CustomLoader from '../common/CustomLoader';
import { projectValidationSchema } from '../common/utils/form-validation';
import Layout from './Layout';
import * as actions from './redux/actions';
import Dropzone from 'react-dropzone';

export class CreateProjectForm extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    document.title = 'Create Project-WebConnect';
  }

  state = {
    showAdvancedSettings: false,
  };

  toggleAdvancedSettings = () => {
    this.setState((prevState) => ({
      showAdvancedSettings: !prevState.showAdvancedSettings,
    }));
  };

  handleDrop = (acceptedFiles, fieldName, setFieldValue) => {
    setFieldValue(fieldName, acceptedFiles[0]);
  };

  renderDropzone = (fieldName, label, setFieldValue, previewUrl) => {
    return (
      <div className="form-group">
        <label className="col-form-label">{label}</label>
        <div className="custom-file">
          <Dropzone
            onDrop={(acceptedFiles) => this.handleDrop(acceptedFiles, fieldName, setFieldValue)}
            accept=".jpg,.jpeg,.gif,.ico,.png"
            maxSize={5242880}
            className="dropzone"
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`dropzone-wrapper ${previewUrl ? 'with-preview' : ''}`}
              >
                <input {...getInputProps()} />
                <div>
                  {previewUrl ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-link cancel-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue(fieldName, null);
                        }}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      <img src={previewUrl} alt="Preview" className="img-thumbnail" />
                    </>
                  ) : (
                    <>
                      Drag & drop or click to select an image{' '}
                      <i
                        className="fas fa-cloud-upload-alt upload-icon"
                        style={{ fontSize: '30px' }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
          <ErrorMessage name={fieldName} component="div" className="invalid-feedback" />
        </div>
      </div>
    );
  };

  handleSubmit = (values, { setSubmitting, setFieldValue }) => {
    const { actions, history, home, login, protocol, builderConfiguration } = this.props;
    const token = login.token;
    setSubmitting(true);
    const cleanedProjectName = values.projectName.replace(/\s+/g, ' ').trim();
    const formData = new FormData();
    formData.append('name', cleanedProjectName);
    formData.append('description', values.projectDescription);
    formData.append('framework', 'BOOTSTRAP');

    if (values.favicon) {
      formData.append('favicon', values.favicon);
    }

    if (values.logo) {
      formData.append('projectLogo', values.logo);
    }

    actions
      .createClientProject({ formData })
      .then((apidata) => {
        if (apidata.uuid && apidata.seoName) {
          const redirectUrl = `${protocol}://${apidata.seoName}.${builderConfiguration}on-boarding/1/v/${apidata.uuid}/?token=${token}`;
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, 1000);
        }
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  render() {
    const { showAdvancedSettings } = this.state;
    const { home } = this.props;
    const { createClientProjectPending } = home;

    return (
      <Layout currentPage="Create Project">
        <div className="page-header min-vh-80 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="col-xl-6 col-lg-8 col-md-8">
                  <div className="card">
                    <div className="card-body">
                      <div className=" text-center p-37">
                        <h3 className="font-weight-bolder">Create Project</h3>
                        <hr className="dark horizontal my-4" />
                      </div>
                      <div className="card-body" style={{ marginTop: '-35px' }}>
                        <Formik
                          initialValues={{
                            projectName: '',
                            favicon: null,
                            logo: null,
                            projectDescription: '',
                          }}
                          validationSchema={projectValidationSchema}
                          validateOnBlur
                          onSubmit={(values, { setSubmitting, setFieldValue }) =>
                            this.handleSubmit(values, { setSubmitting, setFieldValue })
                          }
                        >
                          {({ values, touched, errors, isSubmitting, setFieldValue }) => (
                            <Form encType="multipart/form-data">
                              <div className="form-group">
                                <label className="col-form-label">Name</label>
                                <Field
                                  type="text"
                                  name="projectName"
                                  placeholder="Name"
                                  className={`form-control ${
                                    touched.projectName && errors.projectName ? 'is-invalid' : ''
                                  }`}
                                  style={{
                                    border: '1px solid #ced4da',
                                    borderRadius: '0.25rem',
                                    padding: '0.375rem 0.75rem',
                                  }}
                                />
                                {touched.projectName && errors.projectName && (
                                  <div className="invalid-feedback">{errors.projectName}</div>
                                )}
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-md btn-outline-info w-100 mt-4 mb-0"
                                  onClick={this.toggleAdvancedSettings}
                                  style={{
                                    border: '1px solid #17a2b8',
                                    color: '#17a2b8',
                                  }}
                                >
                                  <i className="fas fa-cog " style={{ fontSize: '12px' }}></i>{' '}
                                  Advanced Settings
                                </button>
                              </div>

                              {showAdvancedSettings && (
                                <>
                                  <div className="form-group">
                                    <label className="col-form-label">Description</label>
                                    <Field
                                      as="textarea"
                                      name="projectDescription"
                                      placeholder="Description"
                                      className={`form-control ${
                                        touched.projectDescription && errors.projectDescription
                                          ? 'is-invalid'
                                          : ''
                                      } inputField`}
                                      style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        padding: '0.375rem 0.75rem',
                                      }}
                                    />
                                    {touched.projectDescription && errors.projectDescription && (
                                      <div className="invalid-feedback">
                                        {errors.projectDescription}
                                      </div>
                                    )}
                                  </div>
                                  {this.renderDropzone(
                                    'favicon',
                                    'Favicon',
                                    setFieldValue,
                                    values.favicon && URL.createObjectURL(values.favicon),
                                    touched,
                                    errors,
                                  )}
                                  {this.renderDropzone(
                                    'logo',
                                    'Icon/Logo Image',
                                    setFieldValue,
                                    values.logo && URL.createObjectURL(values.logo),
                                    touched,
                                    errors,
                                  )}
                                </>
                              )}
                              <hr style={{ borderTop: '1px solid #ddd' }} />
                              <div className="text-center d-flex justify-content-end">
                                <button
                                  type="submit"
                                  disabled={isSubmitting || createClientProjectPending}
                                  className="btn btn-success btn-md text-white mt-4 mb-0"
                                >
                                  <i className="material-icons text-lg position-relative">save</i>{' '}
                                  Create
                                  <CustomLoader
                                    loading={isSubmitting || createClientProjectPending}
                                  />
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    builderConfiguration: process.env.REACT_APP_CONFIGURATION_SURFACE,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectForm);
